define("cosmoz-ui/templates/sites/new", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "zW4v5Akn",
    "block": "[[[10,0],[14,0,\"section\"],[12],[1,\"\\n  \"],[10,\"h1\"],[12],[1,\"Create Station\"],[13],[1,\"\\n  \"],[10,\"hr\"],[12],[13],[1,\"\\n  \"],[8,[39,0],null,[[\"@model\"],[[30,0,[\"model\"]]]],null],[1,\"\\n  \"],[10,\"hr\"],[12],[13],[1,\"\\n  \"],[8,[39,1],null,[[\"@station\",\"@calibrations\"],[[30,0,[\"model\"]],[30,0,[\"model\",\"calibrations\"]]]],null],[1,\"\\n\"],[13]],[],false,[\"site-editor\",\"calibration-editor\"]]",
    "moduleName": "cosmoz-ui/templates/sites/new.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});