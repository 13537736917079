define("cosmoz-ui/adapters/intensity", ["exports", "cosmoz-ui/adapters/backend"], function (_exports, _backend) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _backend.default.extend({
    urlForQuery: function urlForQuery(query, modelName) {
      var f = query.filter;
      var station_no;

      if (f) {
        station_no = f.station_no;
        delete f.station_no;
      } else {
        station_no = query.station_no;
        delete query.station_no;
      }

      if (station_no) {
        return this._buildURL('station', station_no, 'intensities');
      }

      return this._super.apply(this, arguments);
    },
    urlForQueryRecord: function urlForQueryRecord(query, modelName) {
      var f = query.filter;
      var station_no;

      if (f) {
        station_no = f.station_no;
        delete f.station_no;
      } else {
        station_no = query.station_no;
        delete query.station_no;
      }

      if (station_no) {
        return this._buildURL('station', station_no, 'intensities');
      }

      return this._super.apply(this, arguments);
    },

    /**
     @method _buildURL
     @private
     @param {String} modelName
     @param {String} id
     @param {String} extrapart
     @return {String} url
     */
    _buildURL: function _buildURL(modelName, id, extrapart) {
      var path;
      var url = [];
      var host = this.host;
      var prefix = this.urlPrefix();
      path = this.pathForType(modelName);

      if (path) {
        url.push(path);
      } else {
        return null;
      }

      if (id) {
        url.push(encodeURIComponent(id));
      } else {
        return null;
      }

      if (prefix) {
        url.unshift(prefix);
      }

      if (extrapart) {
        url.push(encodeURIComponent(extrapart));
      }

      url = url.join('/');

      if (!host && url && url.charAt(0) !== '/') {
        url = '/' + url;
      }

      return url;
    }
  });

  _exports.default = _default;
});