define("cosmoz-ui/adapters/backend", ["exports", "@ember-data/adapter/rest", "@ember/object", "@ember/service", "cosmoz-ui/config/environment"], function (_exports, _rest, _object, _service, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var APP = _environment.default.APP;

  if (_environment.default.environment === 'development') {// ...
  }

  var _default = _rest.default.extend({
    namespace: 'rest',
    host: APP.BACKEND_HOST,
    session: (0, _service.inject)(),
    headers: (0, _object.computed)('session.data.authenticated.access_token', function () {
      var headers = {};

      if (this.session.isAuthenticated) {
        // OAuth 2
        headers['Authorization'] = "Bearer ".concat(this.session.data.authenticated.access_token);
      }

      return headers; // return {
      //   'API_KEY': 'secret key',
      //   'ANOTHER_HEADER': 'Some header value'
      // };
    }),
    swaggerURL: (0, _object.computed)('host', 'namespace', function () {
      var h = this.host;
      var ns = this.namespace;
      return "" + h + "/" + ns + "/swagger.json";
    }),
    getQueryUrlWithParams: function getQueryUrlWithParams(modelName, query) {
      //This is needed when aquiring the URL+querystring info for querys
      //Ember does not provide this as a unified result. (buildUrl + querystring)
      var adapter = this;
      var url = adapter.buildURL(modelName, null, null, 'query', query);

      if (this.sortQueryParams) {
        query = this.sortQueryParams(query);
      }

      var queryParamDelimiter = url.indexOf('?') > -1 ? '&' : '?';
      url += "".concat(queryParamDelimiter).concat(new URLSearchParams(query).toString());
      return url;
    }
  });

  _exports.default = _default;
});