define("cosmoz-ui/themes/ember-paper", ["exports", "ember-models-table/themes/ember-paper"], function (_exports, _emberPaper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _emberPaper.default;
    }
  });
});