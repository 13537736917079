define("cosmoz-ui/models/user", ["exports", "@ember-data/model", "cosmoz-ui/config/environment", "@ember/service"], function (_exports, _model, _environment, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var APP = _environment.default.APP;

  var _default = _model.default.extend({
    APP: APP,
    email: (0, _model.attr)(),
    displayName: (0, _model.attr)(),
    apiKey: (0, _model.attr)()
  });

  _exports.default = _default;
});