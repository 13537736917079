define("cosmoz-ui/serializers/daily", ["exports", "@ember-data/serializer/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rest.default.extend({
    dailyCount: 0,
    normalize: function normalize(model, hash, prop) {
      this.dailyCount += 1;
      hash.id = this.dailyCount;
      return this._super.apply(this, arguments);
    },
    modelNameFromPayloadKey: function modelNameFromPayloadKey(key) {
      if (key.indexOf('daily') > 0 || key.indexOf('stats') > 0) {
        return 'daily';
      }

      return null;
    }
  });

  _exports.default = _default;
});