define("cosmoz-ui/transforms/isolocal", ["exports", "@ember-data/serializer/transform", "@ember/service"], function (_exports, _transform, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _transform.default.extend({
    moment: (0, _service.inject)(),
    deserialize: function deserialize(isoString) {
      var m = this.moment;
      return m.moment(isoString).local().format(moment.HTML5_FMT.DATETIME_LOCAL);
    },
    serialize: function serialize(localtimeString) {
      var m = this.moment;
      return m.moment(localtimeString).local().format();
    }
  });

  _exports.default = _default;
});