define("cosmoz-ui/serializers/intensity", ["exports", "@ember-data/serializer/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rest.default.extend({
    intensityCount: 0,
    normalize: function normalize(model, hash, prop) {
      this.intensityCount += 1;
      hash.id = this.intensityCount;
      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});