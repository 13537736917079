define("cosmoz-ui/templates/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "OLvW21HT",
    "block": "[[[10,0],[14,0,\"section photosection\"],[12],[1,\"\\n  \"],[10,\"img\"],[15,\"src\",[29,[[30,0,[\"siteImageBaseURL\"]],\"/Hamilton.jpg\"]]],[14,\"alt\",\"Site Photo\"],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"section sitetitle\"],[12],[1,\"\\n  \"],[10,\"h1\"],[12],[1,\"CosmOz\"],[13],[1,\"\\n  \"],[10,\"h2\"],[12],[1,\"Australian Cosmic-Ray Neutron Soil Moisture Monitoring Network\"],[13],[1,\"\\n  \"],[10,\"hr\"],[12],[13],[1,\"\\n  \"],[8,[39,0],null,[[\"@route\"],[\"sites.index\"]],[[\"default\"],[[[[10,\"h2\"],[12],[1,\"Sensor Locations and Data\"],[13]],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\"],[46,[28,[37,2],null,null],null,null,null]],[],false,[\"link-to\",\"component\",\"-outlet\"]]",
    "moduleName": "cosmoz-ui/templates/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});