define("cosmoz-ui/components/cosmoz-many-viz", ["exports", "@ember/component", "@ember/object", "@ember/service", "cosmoz-ui/mixins/with-root"], function (_exports, _component, _object, _service, _withRoot) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_withRoot.default, {
    moment: (0, _service.inject)(),
    init: function init() {
      var m = this.moment;

      this._super.apply(this, arguments);

      this.allSites = null;
      this.selectedStationNo = 0;
      this.selectedStation = null;
      this.selectedTable = "L4";
      this.selectedProperty = "";
      this.autoscaleY = false;
      this.theStore = null;
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var element = this.element;
      this.set('allSites', this.allStations);
      this.set('theStore', this.store);
    },
    selectedStationNoChanged: (0, _object.observer)('selectedStationNo', function () {
      var selectedStationNo = this.selectedStationNo;

      if (selectedStationNo) {
        var self = this;
        this.theStore.findRecord('station', selectedStationNo).then(function (a) {
          self.set('selectedStation', a);
        });
      }
    }),
    propsList: (0, _object.computed)('selectedTable', 'selectedStation', function () {
      var selectedTable = this.selectedTable;
      var selectedStation = this.selectedStation;

      if (selectedStation === null || selectedTable === null || selectedTable === undefined) {
        return null;
      }

      if (selectedTable === "R" || selectedTable === "r") {
        return ['count', 'battery', 'external_temperature', 'external_humidity', 'internal_temperature', 'internal_humidity'];
      } else if (selectedTable === "L1") {
        return ['count', 'battery', 'external_temperature', 'external_humidity', 'internal_temperature', 'internal_humidity'];
      } else if (selectedTable === "L2") {
        return ['count', 'corr_count', 'intensity_corr', "press_corr", "wv_corr"];
      } else if (selectedTable === "L3") {
        return ['soil_moist', 'effective_depth', 'rainfall'];
      } else if (selectedTable === "L4") {
        return ['soil_moist_filtered', 'depth_filtered', 'rainfall'];
      } else if (selectedTable === 'I' || selectedTable === "i") {
        return ['intensity', 'bad_data_flag'];
      } else {
        return null;
      }
    }),
    showChart: (0, _object.computed)('selectedProperty', 'selectedStation', function () {
      var selectedProperty = this.selectedProperty;
      var selectedStation = this.selectedStation;
      return selectedProperty && selectedProperty !== '' && selectedStation;
    }),
    actions: {
      tableSelectboxChanged: function tableSelectboxChanged(ev) {
        this.set('selectedTable', ev);
      },
      siteSelectboxChanged: function siteSelectboxChanged(ev) {
        this.set('selectedStationNo', ev);
      },
      propertySelectboxChanged: function propertySelectboxChanged(ev) {
        this.set('selectedProperty', ev);
      },
      changedAutoscaleY: function changedAutoscaleY(ev) {
        this.set('autoscaleY', ev.target.checked || false);
      }
    }
  });

  _exports.default = _default;
});