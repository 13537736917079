define("cosmoz-ui/adapters/calibration_old", ["exports", "cosmoz-ui/adapters/backend", "ember-data/adapter"], function (_exports, _backend, _adapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _backend.default.extend({});

  _exports.default = _default;
});