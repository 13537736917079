define("cosmoz-ui/components/cosmoz-site-popup", ["exports", "@ember/component", "ember-data", "@ember/object", "cosmoz-ui/mixins/with-root"], function (_exports, _component, _emberData, _object, _withRoot) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_withRoot.default, {
    init: function init() {
      this._super.apply(this, arguments);
    },
    lastDataDatetime: (0, _object.computed)('site.lastDataDatetime', function () {
      var p = this.site.lastDataDatetime.then(function (dt) {
        if (dt === undefined || dt === null) {
          return "Never";
        } else {
          return dt;
        }
      });
      return _emberData.default.PromiseObject.create({
        promise: p
      });
    }),
    lastSoilMoisture: (0, _object.computed)('site.lastSoilMoisture', function () {
      var p = this.site.lastSoilMoisture.then(function (sm) {
        if (sm === undefined || sm === null) {
          return "No Data";
        } else {
          return "" + sm.toFixed(3) + "%";
        }
      });
      return _emberData.default.PromiseObject.create({
        promise: p
      });
    })
  });

  _exports.default = _default;
});