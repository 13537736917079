define("cosmoz-ui/adapters/station", ["exports", "cosmoz-ui/adapters/backend"], function (_exports, _backend) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _backend.default.extend({});

  _exports.default = _default;
});