define("cosmoz-ui/controllers/index", ["exports", "@ember/controller", "cosmoz-ui/mixins/with-root"], function (_exports, _controller, _withRoot) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend(_withRoot.default, {});

  _exports.default = _default;
});