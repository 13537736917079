define("cosmoz-ui/components/form-controls/ff-input", ["exports", "ember-foxy-forms/components/form-controls/ff-input"], function (_exports, _ffInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _ffInput.default;
    }
  });
});