define("cosmoz-ui/models/calibration", ["exports", "@ember-data/model", "cosmoz-ui/config/environment", "@ember/service"], function (_exports, _model, _environment, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var APP = _environment.default.APP;

  if (_environment.default.environment === 'development') {// ...
  }

  var _default = _model.default.extend({
    APP: APP,
    moment: (0, _service.inject)(),
    site_number: (0, _model.belongsTo)('station'),
    date_UTC: (0, _model.attr)('isodate'),
    altitude_m: (0, _model.attr)(),
    sample_location: (0, _model.attr)(),
    calibration_no: (0, _model.attr)(),
    upper_depth_cm: (0, _model.attr)(),
    lower_depth_cm: (0, _model.attr)(),
    cal_start_utc: (0, _model.attr)('isodate'),
    cal_end_utc: (0, _model.attr)('isodate'),
    ave_counts_cph: (0, _model.attr)(),
    ave_pressure_mb: (0, _model.attr)(),
    ave_rel_humidity_pc: (0, _model.attr)(),
    ave_temp_oC: (0, _model.attr)(),
    bulk_density_gcm3: (0, _model.attr)(),
    cutoff_rig: (0, _model.attr)(),
    depth_interval: (0, _model.attr)(),
    distance_from_CRNS_m: (0, _model.attr)(),
    gravimetric_soil_moisture: (0, _model.attr)(),
    latitude: (0, _model.attr)(),
    lattice_g_g: (0, _model.attr)(),
    longitude: (0, _model.attr)(),
    neutron_intensity: (0, _model.attr)(),
    neutron_monitor: (0, _model.attr)(),
    organicC_g_g: (0, _model.attr)(),
    reference_intensity: (0, _model.attr)(),
    sample_dir: (0, _model.attr)(),
    volumetric_soil_moisture: (0, _model.attr)()
  });

  _exports.default = _default;
});