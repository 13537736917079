define("cosmoz-ui/templates/components/cosmoz-many-viz-chart", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "3XhRkEyi",
    "block": "[[[18,1,null]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "cosmoz-ui/templates/components/cosmoz-many-viz-chart.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});