define("cosmoz-ui/components/models-table/themes/ember-paper/expand-toggle", ["exports", "ember-models-table/components/models-table/themes/ember-paper/expand-toggle"], function (_exports, _expandToggle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _expandToggle.default;
    }
  });
});