define("cosmoz-ui/models/observation", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    time: (0, _model.attr)('isodate'),
    mean: (0, _model.attr)(),
    soil_moist: (0, _model.attr)(),
    soil_moist_filtered: (0, _model.attr)(),
    mean_soil_moist: (0, _model.attr)(),
    mean_soil_moist_filtered: (0, _model.attr)(),
    effective_depth: (0, _model.attr)(),
    depth_filtered: (0, _model.attr)(),
    mean_effective_depth: (0, _model.attr)(),
    mean_depth_filtered: (0, _model.attr)(),
    rainfall: (0, _model.attr)(),
    mean_rainfall: (0, _model.attr)(),
    count: (0, _model.attr)(),
    mean_count: (0, _model.attr)(),
    intensity_corr: (0, _model.attr)(),
    mean_intensity_corr: (0, _model.attr)(),
    wv_corr: (0, _model.attr)(),
    mean_wv_corr: (0, _model.attr)(),
    press_corr: (0, _model.attr)(),
    mean_press_corr: (0, _model.attr)(),
    corr_count: (0, _model.attr)(),
    mean_corr_count: (0, _model.attr)(),
    rain: (0, _model.attr)(),
    mean_rain: (0, _model.attr)(),
    battery: (0, _model.attr)(),
    mean_battery: (0, _model.attr)(),
    external_temperature: (0, _model.attr)(),
    mean_external_temperature: (0, _model.attr)(),
    external_humidity: (0, _model.attr)(),
    mean_external_humidity: (0, _model.attr)(),
    internal_temperature: (0, _model.attr)(),
    mean_internal_temperature: (0, _model.attr)(),
    internal_humidity: (0, _model.attr)(),
    mean_internal_humidity: (0, _model.attr)()
  });

  _exports.default = _default;
});