define("cosmoz-ui/components/form-controls/ff-time", ["exports", "ember-foxy-forms/components/form-controls/ff-time"], function (_exports, _ffTime) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _ffTime.default;
    }
  });
});