define("cosmoz-ui/templates/login", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Q1/wCWNG",
    "block": "[[[10,\"h3\"],[12],[1,\"Please wait while redirecting.\"],[13]],[],false,[]]",
    "moduleName": "cosmoz-ui/templates/login.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});