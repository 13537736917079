define("cosmoz-ui/models/calibration_old", ["exports", "@ember-data/model", "cosmoz-ui/config/environment", "@ember/service"], function (_exports, _model, _environment, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var APP = _environment.default.APP;

  if (_environment.default.environment === 'development') {// ...
  }

  var _default = _model.default.extend({
    APP: APP,
    moment: (0, _service.inject)(),
    site_no: (0, _model.belongsTo)('station'),
    date: (0, _model.attr)('isolocal'),
    label: (0, _model.attr)(),
    loc: (0, _model.attr)(),
    depth: (0, _model.attr)(),
    vol: (0, _model.attr)(),
    total_wet: (0, _model.attr)(),
    total_dry: (0, _model.attr)(),
    tare: (0, _model.attr)(),
    soil_wet: (0, _model.attr)(),
    soil_dry: (0, _model.attr)(),
    gwc: (0, _model.attr)(),
    bd: (0, _model.attr)(),
    vwc: (0, _model.attr)()
  });

  _exports.default = _default;
});