define("cosmoz-ui/helpers/icon", ["exports", "ember-leaflet/helpers/icon"], function (_exports, _icon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _icon.default;
    }
  });
  Object.defineProperty(_exports, "icon", {
    enumerable: true,
    get: function get() {
      return _icon.icon;
    }
  });
});