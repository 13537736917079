define("cosmoz-ui/authenticators/oauth2-auth-code-pkce", ["exports", "ember-simple-auth/authenticators/base", "@ember/utils", "@ember/array", "@ember/service", "@ember/debug", "rsvp", "crypto-js", "@ember/runloop", "@ember/polyfills"], function (_exports, _base, _utils, _array, _service, _debug, _rsvp, _cryptoJs, _runloop, _polyfills) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // app/authenticators/custom.js
  var assign = _polyfills.assign || _polyfills.merge;
  var keys = Object.keys || _polyfills.keys; // Ember.keys deprecated in 1.13

  function generate_random_string(string_length) {
    var random_string = '';
    var random_ascii;

    for (var i = 0; i < string_length; i++) {
      random_ascii = Math.floor(Math.random() * 25 + 97);
      random_string += String.fromCharCode(random_ascii);
    }

    return random_string;
  }

  var _default = _base.default.extend({
    /**
     The client_id to be sent to the authentication server (see
     https://tools.ietf.org/html/rfc6749#appendix-A.1). __This should only be
     used for statistics or logging etc. as it cannot actually be trusted since
     it could have been manipulated on the client!__
      @property clientId
     @type String
     @default null
     @public
     */
    clientId: null,

    /**
     The endpoint on the server that authentication and token refresh requests
     are sent to.
      @property serverTokenEndpoint
     @type String
     @default '/token'
     @public
     */
    serverTokenEndpoint: '/token',

    /**
     The endpoint on the server that token revocation requests are sent to. Only
     set this if the server actually supports token revocation. If this is
     `null`, the authenticator will not revoke tokens on session invalidation.
      __If token revocation is enabled but fails, session invalidation will be
     intercepted and the session will remain authenticated (see
     {{#crossLink "OAuth2PasswordGrantAuthenticator/invalidate:method"}}{{/crossLink}}).__
      @property serverTokenRevocationEndpoint
     @type String
     @default null
     @public
     */
    serverTokenRevocationEndpoint: null,

    /**
     Sets whether the authenticator automatically refreshes access tokens if the
     server supports it.
      @property refreshAccessTokens
     @type Boolean
     @default true
     @public
     */
    refreshAccessTokens: true,

    /**
     The offset time in milliseconds to refresh the access token. This must
     return a random number. This randomization is needed because in case of
     multiple tabs, we need to prevent the tabs from sending refresh token
     request at the same exact moment.
      __When overriding this property, make sure to mark the overridden property
     as volatile so it will actually have a different value each time it is
     accessed.__
      @property tokenRefreshOffset
     @type Integer
     @default a random number between 5 and 10
     @public
     */
    get tokenRefreshOffset() {
      var min = 5;
      var max = 10;
      return (Math.floor(Math.random() * (max - min)) + min) * 1000;
    },

    _refreshTokenTimeout: null,

    /**
     Restores the session from a session data object; __will return a resolving
     promise when there is a non-empty `access_token` in the session data__ and
     a rejecting promise otherwise.
      If the server issues
     If the server issues
     [expiring access tokens](https://tools.ietf.org/html/rfc6749#section-5.1)
     and there is an expired access token in the session data along with a
     refresh token, the authenticator will try to refresh the access token and
     return a promise that resolves with the new access token if the refresh was
     successful. If there is no refresh token or the token refresh is not
     successful, a rejecting promise will be returned.
      @method restore
     @param {Object} data The data to restore the session from
     @return {Ember.RSVP.Promise} A promise that when it resolves results in the session becoming or remaining authenticated. If restoration fails, the promise will reject with the server response (in case the access token had expired and was refreshed using a refresh token); however, the authenticator reads that response already so if you need to read it again you need to clone the response object first
     @public
     */
    restore: function restore(data) {
      var _this = this;

      return new _rsvp.default.Promise(function (resolve, reject) {
        var now = new Date().getTime();

        var refreshAccessTokens = _this.get('refreshAccessTokens');

        if (!(0, _utils.isEmpty)(data['expires_at']) && data['expires_at'] < now) {
          if (refreshAccessTokens) {
            _this._refreshAccessToken(data['expires_in'], data['refresh_token']).then(resolve, reject);
          } else {
            reject();
          }
        } else {
          if ((0, _utils.isEmpty)(data['access_token'])) {
            reject();
          } else {
            _this._scheduleAccessTokenRefresh(data['expires_in'], data['expires_at'], data['refresh_token']);

            resolve(data);
          }
        }
      });
    },
    authenticate: function authenticate(_ref) {
      var _this2 = this;

      var session = _ref.session,
          scope = _ref.scope,
          headers = _ref.headers,
          loginRouteName = _ref.loginRouteName,
          preLoginUri = _ref.preLoginUri;
      var code = session.get('data.accessCode');
      var codeVerifier = session.get('data.codeVerifier');

      if (!(0, _utils.isEmpty)(codeVerifier) && !(0, _utils.isEmpty)(code)) {
        return new _rsvp.default.Promise(function (resolve, reject) {
          var redirectURI = _this2.get('redirectURI');

          var data = {
            'grant_type': 'authorization_code',
            'code': code,
            'code_verifier': codeVerifier,
            redirect_uri: redirectURI
          };

          var serverTokenEndpoint = _this2.get('serverTokenEndpoint');

          var scopesString = (0, _array.makeArray)(scope).join(' ');

          if (!(0, _utils.isEmpty)(scopesString)) {
            data.scope = scopesString;
          }

          _this2.makeRequest(serverTokenEndpoint, data, headers).then(function (response) {
            (0, _runloop.run)(function () {
              session.set('data.accessCode', null);
              session.set('data.codeVerifier', null);
              var accessToken = response.access_token;

              if ((0, _utils.isEmpty)(accessToken)) {
                reject(new Error('access_token is missing in server response'));
              }

              var expiresAt = _this2._absolutizeExpirationTime(response['expires_in']);

              _this2._scheduleAccessTokenRefresh(response['expires_in'], expiresAt, response['refresh_token']);

              if (!(0, _utils.isEmpty)(expiresAt)) {
                response = assign(response, {
                  'expires_at': expiresAt
                });
              }

              resolve(response);
            });
          }, function (response) {
            session.set('data.accessCode', null);
            session.set('data.codeVerifier', null);
            var errMsg = response.responseJSON && response.responseJSON.error || response.statusText;
            (0, _runloop.run)(null, reject, new Error(errMsg));
          });
        });
      } else {
        var redirectURI = this.get('redirectURI');
        var data = {
          'response_type': "code",
          'redirect_uri': redirectURI,
          'code_challenge_method': "S256"
        };
        var code_verifier = generate_random_string(43);

        var hashDigest = _cryptoJs.default.enc.Base64.stringify(_cryptoJs.default.SHA256(code_verifier));

        data.code_challenge = hashDigest.replace("+", "-").replace("/", "_").replace(/=+$/, "");
        session.set('data.codeVerifier', code_verifier);
        var serverAuthEndpoint = this.get('serverAuthEndpoint');
        var scopesString = (0, _array.makeArray)(scope).join(' ');

        if (!(0, _utils.isEmpty)(scopesString)) {
          data.scope = scopesString;
        }

        var stateChallenge = generate_random_string(15);
        session.set('data.stateChallenge', stateChallenge);
        session.set('data.preLoginUri', preLoginUri); //login route is needed as we need to call it a second time
        //to do the round trip which gets the token

        session.set('data.loginRouteName', loginRouteName || 'login');
        var stateObj = {
          stateChallenge: stateChallenge,
          loginRouteName: session.get('data.loginRouteName')
        };
        data.state = JSON.stringify(stateObj);
        return this.makeRedirect(serverAuthEndpoint, data);
      }
    },

    /**
     If token revocation is enabled, this will revoke the access token (and the
     refresh token if present). If token revocation succeeds, this method
     returns a resolving promise, otherwise it will return a rejecting promise,
     thus intercepting session invalidation.
      If token revocation is not enabled this method simply returns a resolving
     promise.
      @method invalidate
     @param {Object} data The current authenticated session data
     @return {Ember.RSVP.Promise} A promise that when it resolves results in the session being invalidated. If invalidation fails, the promise will reject with the server response (in case token revocation is used); however, the authenticator reads that response already so if you need to read it again you need to clone the response object first
     @public
     */
    invalidate: function invalidate(data) {
      var _this3 = this;

      var serverTokenRevocationEndpoint = this.get('serverTokenRevocationEndpoint');

      function success(resolve) {
        _runloop.run.cancel(this._refreshTokenTimeout);

        delete this._refreshTokenTimeout;
        resolve();
      }

      return new _rsvp.default.Promise(function (resolve) {
        if ((0, _utils.isEmpty)(serverTokenRevocationEndpoint)) {
          success.apply(_this3, [resolve]);
        } else {
          var requests = [];
          (0, _array.A)(['access_token', 'refresh_token']).forEach(function (tokenType) {
            var token = data[tokenType];

            if (!(0, _utils.isEmpty)(token)) {
              requests.push(_this3.makeRequest(serverTokenRevocationEndpoint, {
                'token_type_hint': tokenType,
                token: token
              }));
            }
          });

          var succeed = function succeed() {
            success.apply(_this3, [resolve]);
          };

          _rsvp.default.all(requests).then(succeed, succeed);
        }
      });
    },

    /**
     Makes a request to the OAuth 2.0 server.
      @method makeRequest
     @param {String} url The request URL
     @param {Object} data The request data
     @param {Object} headers Additional headers to send in request
     @return {Promise} A promise that resolves with the response object
     @protected
     */
    makeRequest: function makeRequest(url, data) {
      var headers = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      headers['Content-Type'] = 'application/x-www-form-urlencoded';
      var clientId = this.get('clientId');

      if (!(0, _utils.isEmpty)(clientId)) {
        data['client_id'] = this.get('clientId');
      }

      var body = keys(data).map(function (key) {
        return "".concat(encodeURIComponent(key), "=").concat(encodeURIComponent(data[key]));
      }).join('&');
      var options = {
        body: body,
        headers: headers,
        method: 'POST'
      };
      return new _rsvp.default.Promise(function (resolve, reject) {
        fetch(url, options).then(function (response) {
          response.text().then(function (text) {
            try {
              var json = JSON.parse(text);

              if (!response.ok) {
                response.responseJSON = json;
                reject(response);
              } else {
                resolve(json);
              }
            } catch (SyntaxError) {
              response.responseText = text;
              reject(response);
            }
          });
        }).catch(reject);
      });
    },

    /**
     Makes a browser redirect
      @method makeRedirect
     @param {String} url The redirect URL
     @param {Object} data The query data
     @return {Promise} A promise that to nothing
     @protected
     */
    makeRedirect: function makeRedirect(url, data) {
      var _this4 = this;

      var clientId = this.get('clientId');

      if (!(0, _utils.isEmpty)(clientId)) {
        data['client_id'] = this.get('clientId');
      }

      var qs = keys(data).map(function (key) {
        return "".concat(encodeURIComponent(key), "=").concat(encodeURIComponent(data[key]));
      }).join('&');
      var goToUrl = "".concat(url, "?").concat(qs);
      return new _rsvp.default.Promise(function (resolve, reject) {
        (0, _runloop.next)(_this4, function () {
          window.location.href = goToUrl;
          reject(null); //Return false, because we're not yet Authenticated
        });
      });
    },
    _absolutizeExpirationTime: function _absolutizeExpirationTime(expiresIn) {
      if (!(0, _utils.isEmpty)(expiresIn)) {
        return new Date(new Date().getTime() + expiresIn * 1000).getTime();
      }
    },
    _scheduleAccessTokenRefresh: function _scheduleAccessTokenRefresh(expiresIn, expiresAt, refreshToken) {
      var refreshAccessTokens = this.get('refreshAccessTokens');

      if (refreshAccessTokens) {
        var now = new Date().getTime();

        if ((0, _utils.isEmpty)(expiresAt) && !(0, _utils.isEmpty)(expiresIn)) {
          expiresAt = new Date(now + expiresIn * 1000).getTime();
        }

        var offset = this.get('tokenRefreshOffset');

        if (!(0, _utils.isEmpty)(refreshToken) && !(0, _utils.isEmpty)(expiresAt) && expiresAt > now - offset) {
          _runloop.run.cancel(this._refreshTokenTimeout);

          delete this._refreshTokenTimeout;

          if (!Ember.testing) {
            this._refreshTokenTimeout = _runloop.run.later(this, this._refreshAccessToken, expiresIn, refreshToken, expiresAt - now - offset);
          }
        }
      }
    },
    _refreshAccessToken: function _refreshAccessToken(expiresIn, refreshToken) {
      var _this5 = this;

      var data = {
        'grant_type': 'refresh_token',
        'refresh_token': refreshToken
      };
      var serverTokenEndpoint = this.get('serverTokenEndpoint');
      return new _rsvp.default.Promise(function (resolve, reject) {
        _this5.makeRequest(serverTokenEndpoint, data).then(function (response) {
          (0, _runloop.run)(function () {
            expiresIn = response['expires_in'] || expiresIn;
            refreshToken = response['refresh_token'] || refreshToken;

            var expiresAt = _this5._absolutizeExpirationTime(expiresIn);

            var data = assign(response, {
              'expires_in': expiresIn,
              'expires_at': expiresAt,
              'refresh_token': refreshToken
            });

            _this5._scheduleAccessTokenRefresh(expiresIn, null, refreshToken);

            _this5.trigger('sessionDataUpdated', data);

            resolve(data);
          });
        }, function (response) {
          (true && (0, _debug.warn)("Access token could not be refreshed - server responded with ".concat(response.responseJSON, "."), false, {
            id: 'ember-simple-auth.failedOAuth2TokenRefresh'
          }));
          reject();
        });
      });
    }
  });

  _exports.default = _default;
});