define("cosmoz-ui/mixins/with-root", ["exports", "@ember/object/mixin", "cosmoz-ui/config/environment"], function (_exports, _mixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    rootURL: _environment.default.rootURL,
    siteImageBaseURL: _environment.default.APP.IMAGE_BASE_URL
  });

  _exports.default = _default;
});