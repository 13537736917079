define("cosmoz-ui/templates/components/cosmoz-site-popup", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "MVZAbHH5",
    "block": "[[[10,\"h3\"],[12],[1,[30,0,[\"site\",\"site_name\"]]],[13],[1,\"\\n\"],[10,0],[14,5,\"width: 440px\"],[12],[1,\"\\n  \"],[10,0],[14,5,\"display: inline-block; width: 130px; vertical-align: top;\"],[12],[1,\"\\n    \"],[10,\"img\"],[15,\"src\",[29,[[30,0,[\"siteImageBaseURL\"]],\"/\",[30,0,[\"site\",\"site_photo_name\"]]]]],[14,\"alt\",\"Site Photo\"],[14,5,\"float:right;\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,5,\"display: inline-block; width: 170px; padding-left: 8px;\"],[12],[1,\"\\n\\n    \"],[10,\"h4\"],[12],[1,\"Site No. \"],[1,[30,0,[\"site\",\"site_no\"]]],[10,\"br\"],[12],[13],[1,\"\\n\"],[41,[30,0,[\"site\",\"is_calibrated\"]],[[[1,\"        \"],[10,1],[14,0,\"calibrated_site\"],[12],[1,\"Calibrated.\"],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,1],[14,0,\"uncalibrated_site\"],[12],[1,\"Uncalibrated!\"],[13],[1,\"\\n\"]],[]]],[1,\"      \"],[10,\"br\"],[12],[13],[1,\"\\n      \"],[10,1],[12],[1,\"Status: \"],[1,[30,0,[\"site\",\"dynamicStatus\",\"content\"]]],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"ul\"],[14,0,\"site-popup-value-list\"],[12],[1,\"\\n      \"],[10,\"li\"],[12],[1,\"Installed: \"],[1,[28,[35,1],[[30,0,[\"site\",\"installation_date\"]],\"YYYY/MM/DD\"],null]],[13],[1,\"\\n      \"],[10,\"li\"],[12],[1,\"Last Data: \"],[41,[30,0,[\"lastDataDatetime\",\"isPending\"]],[[[1,\"...\"]],[]],[[[1,[28,[35,1],[[30,0,[\"lastDataDatetime\",\"content\"]],\"YYYY/MM/DD\"],null]]],[]]],[13],[1,\"\\n      \"],[10,\"li\"],[12],[1,\"Last Soil Moisture: \"],[41,[30,0,[\"lastSoilMoisture\",\"isPending\"]],[[[1,\"...\"]],[]],[[[1,[30,0,[\"lastSoilMoisture\",\"content\"]]]],[]]],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[8,[39,2],null,[[\"@route\",\"@model\"],[\"sites.site\",[30,0,[\"site\",\"site_no\"]]]],[[\"default\"],[[[[1,\"View Sensor information and data\"]],[]]]]],[1,\"\\n\"],[18,1,null]],[\"&default\"],false,[\"if\",\"moment-format\",\"link-to\",\"yield\"]]",
    "moduleName": "cosmoz-ui/templates/components/cosmoz-site-popup.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});