define("cosmoz-ui/components/commit-buttons", ["exports", "ember-foxy-forms/components/commit-buttons"], function (_exports, _commitButtons) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _commitButtons.default;
    }
  });
});