define("cosmoz-ui/components/cosmoz-many-viz-chart", ["exports", "@ember/component", "@ember/runloop", "@ember/object", "@ember/service", "@amcharts/amcharts4/core", "@amcharts/amcharts4/charts", "@amcharts/amcharts4/themes/kelly"], function (_exports, _component, _runloop, _object, _service, am4core, am4charts, _kelly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //import am4themes_animated from "@amcharts/amcharts4/themes/animated";
  am4core.useTheme(_kelly.default); //am4core.useTheme(am4themes_animated);

  var _default = _component.default.extend({
    moment: (0, _service.inject)(),
    init: function init() {
      this._super.apply(this, arguments);

      this.chart = null;
      this.data = [];
      this.preview_data = [];
      this.preview_series = null;
      this.series1 = null;
      this.series1ready = false;
      this.valueAxis1 = null;
      this.chartIsReady = false;
      this.dynmode = false;
      this.dateAxis = null;
      this.begin_time = 0;
      this.end_time = 0;
      this.mySelectedStation = null;
      this.mySelectedProperty = "";
      this.mySelectedTable = 4;
      this.myAutoscaleY = false;
    },
    scheduleDynMode: function scheduleDynMode() {
      (0, _runloop.later)(this, function () {
        this.set('dynmode', true);
        console.log("ready...");
      }, 1000);
    },
    seriesReadyChanged: (0, _object.observer)("chartIsReady", "series1ready", function () {
      var chartIsReady = this.chartIsReady;
      var series1ready = this.series1ready;

      if (chartIsReady && series1ready) {
        (0, _runloop.debounce)(this, "scheduleDynMode", 100);
      }
    }),
    getNewData: function getNewData(args) {
      var m = this.moment;
      var begin_time = args.begin_time,
          end_time = args.end_time;
      this.set('dynmode', false);
      this.set('series1ready', false);
      console.log("not ready");
      this.set('begin_time', begin_time);
      this.set('end_time', end_time);
      var table = this.mySelectedTable;
      var site = this.mySelectedStation;
      var property = this.mySelectedProperty; //let mean_property = "mean_"+property;

      var mean_property = "mean";

      if (!site) {
        return null;
      }

      var duration_millis = end_time - begin_time;
      var aggregate_seconds = 0;

      if (duration_millis > 2678400000) {
        var duration_seconds = (duration_millis - duration_millis % 1000) / 1000;
        aggregate_seconds = (duration_seconds - duration_seconds % 1000) / 1000;
      }

      var begin_date = m.moment(begin_time);
      var end_date = m.moment(end_time);
      var dateAxis = this.dateAxis;
      var operation;

      if (table.lastIndexOf("L", 0) === 0) {
        var level = table.substring(1);
        operation = site.getObservations(begin_date, end_date, aggregate_seconds, level, false, property);
      } else if (table.lastIndexOf("R", 0) === 0) {
        operation = site.getObservations(begin_date, end_date, aggregate_seconds, 0, false, property);
      } else if (table.lastIndexOf("I", 0) === 0) {
        operation = site.getIntensities(begin_date, end_date, aggregate_seconds, false, property);
      }

      var self = this;
      operation.then(function (ap) {
        var series1 = self.get('series1');

        if (ap.length < 1) {
          var empty1 = [];
          self.set('data', empty1);
          series1.data = empty1;
          return;
        }

        var first_obj = ap.firstObject;
        var last_obj = ap.lastObject;
        var first_time = first_obj.time;
        var last_time = last_obj.time; //console.log("First Time: "+first_time);
        //console.log("Last Time: "+last_time);

        var getProp;

        if (aggregate_seconds > 0) {
          getProp = mean_property;
          dateAxis.baseInterval = {
            timeUnit: "second",
            count: aggregate_seconds
          };
        } else {
          getProp = property;
          dateAxis.baseInterval = {
            timeUnit: "minute",
            count: 60
          };
        }

        var data = ap.map(function (o) {
          var d = {
            time: o.get('time')
          };
          d[property] = o.get(getProp);
          return d;
        });
        self.set('data', data);
        series1.data = data;
        self.dateAxis.zoomToDates(first_time, last_time);
        self.set('begin_time', first_time.getTime());
        self.set('end_time', last_time.getTime()); //self.set('begin_time', begin_date.valueOf());
        //self.set('end_time', end_date.valueOf());
        //self.chart.invalidate();
      });
    },
    dateAxisChanged: function dateAxisChanged(ev) {
      var dynmode = this.dynmode;

      if (!dynmode) {
        return;
      }

      var old_begin_time = this.begin_time;
      var old_end_time = this.end_time;
      var new_begin_time = ev.target.minZoomed;
      var new_end_time = ev.target.maxZoomed;

      if (old_begin_time !== new_begin_time || old_end_time !== new_end_time) {
        //let begin_time_offset = (new_begin_time - old_begin_time)/1000;
        //let end_time_offset = (new_end_time - old_end_time)/1000;
        //console.log("B: " + (begin_time_offset/60) + " mins (" + begin_time_offset + " seconds)");
        //console.log("E: " + (end_time_offset/60) + " mins (" + end_time_offset + " seconds)");
        (0, _runloop.debounce)(this, 'getNewData', {
          begin_time: new_begin_time,
          end_time: new_end_time
        }, 20);
      }
    },
    doChartIsReady: function doChartIsReady() {
      var m = this.moment;
      var preview_series = this.preview_series;
      var series1 = this.series1;
      var dateAxis = this.dateAxis;
      var self = this;
      this.set('dynmode', false);
      this.set('series1ready', false);
      var site = this.mySelectedStation;

      if (!site) {
        return null;
      }

      var initial_display_from_date = m.utc();
      var lastObservation = null;
      var table = this.mySelectedTable;
      var property = this.mySelectedProperty; //let mean_property = "mean_"+property;

      var mean_property = "mean";
      var operation;

      if (table.lastIndexOf("L", 0) === 0) {
        var level = table.substring(1);
        operation = site.getLastObservations(1, level, false);
      } else if (table.lastIndexOf("R", 0) === 0) {
        operation = site.getLastObservations(1, 0, false);
      } else if (table.lastIndexOf("I", 0) === 0) {
        // Intensities is not related to lastobservations, but we need to
        // establish a point in time to look back from. Just choose Table L0.
        operation = site.getLastObservations(1, 0, false);
      }

      operation.then(function (ap) {
        if (ap.length > 0) {
          var o = ap.firstObject;
          lastObservation = {
            time: o.get('time')
          };
          lastObservation[property] = o.get(property);
          initial_display_from_date = m.moment(lastObservation.time).utc(); //console.info("Got known data date is: "+ap[0].time)
        }

        return initial_display_from_date;
      }).then(function (from_date) {
        if (table.lastIndexOf("L", 0) === 0) {
          var _level = table.substring(1);

          operation = site.get24hAggregate(from_date, _level, false, false, property);
        } else if (table.lastIndexOf("R", 0) === 0) {
          operation = site.get24hAggregate(from_date, 0, false, false, property);
        } else if (table.lastIndexOf("I", 0) === 0) {
          // Intensities is not related to lastobservations, but we need to
          // establish a point in time to look back from. Just choose Table L0.
          operation = site.getAllIntensitiesDaily(from_date, false, false, property);
        }

        return operation;
      }).then(function (obs) {
        var preview_data = obs.map(function (o) {
          var d = {
            time: o.get('time')
          };
          d[property] = o.get(mean_property);
          return d;
        });

        if (lastObservation) {
          preview_data.pushObject({
            "time": lastObservation.time,
            property: 0.0
          });
        }

        self.set('preview_data', preview_data);
        preview_series.data = preview_data;
        preview_series.hide();
        return initial_display_from_date;
      }).then(function (from_date) {
        if (table.lastIndexOf("L", 0) === 0) {
          var _level2 = table.substring(1);

          operation = site.get6Months(from_date, _level2, false, property);
        } else if (table.lastIndexOf("R", 0) === 0) {
          operation = site.get6Months(from_date, 0, false, property);
        } else if (table.lastIndexOf("I", 0) === 0) {
          operation = site.getIntensitiesYear(from_date, false, property);
        }

        return operation;
      }).then(function (ap) {
        if (ap.length < 1) {
          var empty1 = [];
          self.set('data', empty1);
          series1.data = empty1;
          return;
        }

        var data = ap.map(function (o) {
          var d = {
            time: o.get('time')
          };
          d[property] = o.get(mean_property);
          return d;
        });
        var first_obj = ap.firstObject;
        var first_time = first_obj.time;
        var last_obj = ap.lastObject;
        var last_time = last_obj.time;
        var count = ap.length;
        var msGap;

        if (count > 2) {
          msGap = (last_time - first_time) / (count - 1);
        } else {
          msGap = last_time - first_time;
        }

        if (lastObservation) {
          data.pushObject(lastObservation);
          last_time = lastObservation.time;
        }

        self.set('data', data);
        dateAxis.baseInterval = {
          timeUnit: "second",
          count: msGap / 1000.0
        };
        series1.data = data;
        dateAxis.zoomToDates(first_time, last_time);
        self.set('begin_time', first_time.getTime());
        self.set('end_time', last_time.getTime()); //series.invalidate();
      }).then(function () {
        (0, _runloop.later)(self, 'set', 'chartIsReady', true, 800);
      });
    },
    chartReady: function chartReady(ev) {
      var chartIsReady = this.chartIsReady;

      if (!chartIsReady) {
        this.doChartIsReady();
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.mySelectedStation = this.selectedStation;
      this.mySelectedTable = this.selectedTable;
      this.mySelectedProperty = this.selectedProperty;
      this.myAutoscaleY = this.autoscaleY;
      this.set('dynmode', false);
      var chart = am4core.create(this.elementId, am4charts.XYChart);
      chart.preloader.disabled = true;
      chart.dateFormatter.dateFormat = "yyyy-MM-dd HH:mm";
      var dateAxisChanged = this.dateAxisChanged;
      var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      dateAxis.events.on("selectionextremeschanged", dateAxisChanged.bind(this));
      dateAxis.renderer.grid.template.location = 0;
      dateAxis.renderer.labels.template.rotation = -66; //dateAxis.renderer.minGridDistance = 0;

      dateAxis.renderer.minGridDistance = 18;
      dateAxis.baseInterval = {
        timeUnit: "minute",
        count: 60
      }; //dateAxis.groupData = false;

      dateAxis.tooltipDateFormat = "yyyy-MM-dd";
      dateAxis.title.text = "Timestamp (UTC)";
      this.set('dateAxis', dateAxis);
      var dateAxisTooltip = dateAxis.tooltip;
      dateAxisTooltip.background.fill = am4core.color("#120f1d");
      dateAxisTooltip.background.strokeWidth = 1;
      dateAxisTooltip.background.cornerRadius = 3;
      dateAxisTooltip.background.pointerLength = 10;
      var dropShadow = new am4core.DropShadowFilter();
      dropShadow.dy = 1;
      dropShadow.dx = 1;
      dropShadow.opacity = 0.5;
      dateAxisTooltip.filters.push(dropShadow);
      chart.scrollbarX = new am4charts.XYChartScrollbar();
      chart.scrollbarX.orientation = "horizontal";
      chart.scrollbarX.updateWhileMoving = false;
      chart.scrollbarX.parent = chart.topAxesContainer;
      chart.cursor = new am4charts.XYCursor();
      chart.legend = new am4charts.Legend();
      var chartReady = this.chartReady;
      chart.events.on("ready", chartReady.bind(this));
      this.set('chart', chart);
      (0, _runloop.later)(this, function () {
        this.reBuildChart();
      }, 1);
    },
    reBuildChart: function reBuildChart() {
      var chart = this.chart;
      var init_data = [];
      var init_preview_data = [];
      var selectedProperty = this.mySelectedProperty; //chart.dateFormatter.inputDateFormat = "yyyy-MM-dd'T'HH:mm:ss'Z'";

      chart.yAxes.clear();
      var valueAxis1 = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis1.title.text = "Measure";

      if (this.autoscaleY) {
        valueAxis1.min = undefined;
      } else {
        valueAxis1.min = 0;
      }

      valueAxis1.tooltip.disabled = false;
      valueAxis1.renderer.line.strokeOpacity = 1;
      valueAxis1.renderer.line.strokeWidth = 2;
      this.set("valueAxis1", valueAxis1); //valueAxis1.renderer.minWidth = 35;
      // //rainfallAxis.title.rotatation = 180;
      //rainfallAxis.renderer.minWidth = 35;
      // let valueAxis3 = chart.yAxes.push(new am4charts.ValueAxis());
      // valueAxis3.min = 0;
      // valueAxis3.tooltip.disabled = false;
      // valueAxis3.renderer.line.strokeOpacity = 1;
      // valueAxis3.renderer.line.strokeWidth = 2;
      // valueAxis3.renderer.opposite = true;
      // //valueAxis3.renderer.minWidth = 35;

      chart.series.clear();
      var series1 = chart.series.push(new am4charts.LineSeries());
      series1.data = init_data;
      series1.yAxis = valueAxis1;
      series1.dataFields.dateX = "time";
      series1.dataFields.valueY = selectedProperty;
      series1.name = selectedProperty;
      series1.strokeWidth = 2;
      series1.minBulletDistance = 0;
      series1.connect = false;
      series1.autoGapCount = 3.0;
      series1.showOnInit = false;
      series1.stroke = "#3cca5e";
      series1.fill = "#2de968"; //valueAxis1.renderer.line.stroke = series1.stroke;

      series1.tooltipText = "Time: [bold]{dateX}[/]\n" + selectedProperty + " [bold]{valueY}[/]";
      series1.events.on("validated", function () {
        this.set("series1ready", true);
      }, this);
      this.set('series1', series1);
      var preview_series = chart.series.push(new am4charts.LineSeries());
      preview_series.data = init_preview_data;
      preview_series.hiddenInLegend = true;
      preview_series.dataFields.dateX = "time";
      preview_series.dataFields.valueY = selectedProperty;
      preview_series.minBulletDistance = 0;
      preview_series.connect = false;
      preview_series.autoGapCount = 3.0;
      preview_series.showOnInit = false;
      this.set("preview_series", preview_series);
      chart.scrollbarX.series.clear();
      chart.scrollbarX.series.push(preview_series);
      var scrollbarXAxis = chart.scrollbarX.scrollbarChart.xAxes.getIndex(0);
      scrollbarXAxis.baseInterval = {
        timeUnit: "hour",
        count: 48
      };
      scrollbarXAxis.renderer.labels.template.rotation = 0;
      scrollbarXAxis.renderer.minGridDistance = 40;
      scrollbarXAxis.renderer.inside = false;
    },
    propertyChanged: (0, _object.observer)('selectedProperty', function () {
      this.set('mySelectedProperty', this.selectedProperty);
      this.set('preview_series', null);
      this.set('series1', null);
      this.set('chartIsReady', false);
      (0, _runloop.later)(this, function () {
        this.reBuildChart();
        (0, _runloop.later)(this, function () {
          this.chartReady();
        }, 1);
      }, 1);
    }),
    levelChanged: (0, _object.observer)('selectedTable', function () {
      this.set('mySelectedTable', this.selectedTable);
      this.set('preview_series', null);
      this.set('series1', null);
      this.set('chartIsReady', false);
      (0, _runloop.later)(this, function () {
        this.reBuildChart();
        (0, _runloop.later)(this, function () {
          this.chartReady();
        }, 1);
      }, 1);
    }),
    stationChanged: (0, _object.observer)('selectedStation', function () {
      this.set('mySelectedStation', this.selectedStation);
      this.set('preview_series', null);
      this.set('series1', null);
      this.set('chartIsReady', false);
      (0, _runloop.later)(this, function () {
        this.reBuildChart();
        (0, _runloop.later)(this, function () {
          this.chartReady();
        }, 1);
      }, 1);
    }),
    autoscaleYChanged: (0, _object.observer)('autoscaleY', function () {
      this.set('myAutoscaleY', this.autoscaleY);
      var chart = this.chart;
      var valueAxis1 = this.valueAxis1;

      if (chart && valueAxis1) {
        if (this.autoscaleY) {
          valueAxis1.min = undefined;
        } else {
          valueAxis1.min = 0;
        }
      }
    }),
    willDestroyElement: function willDestroyElement() {
      if (this.chart) {
        this.chart.dispose();
      }

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});