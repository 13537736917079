define("cosmoz-ui/components/cosmoz-chart", ["exports", "@ember/component", "@ember/runloop", "@ember/object", "@ember/service", "@amcharts/amcharts4/core", "@amcharts/amcharts4/charts", "@amcharts/amcharts4/themes/kelly"], function (_exports, _component, _runloop, _object, _service, am4core, am4charts, _kelly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //import am4themes_animated from "@amcharts/amcharts4/themes/animated";
  am4core.useTheme(_kelly.default); //am4core.useTheme(am4themes_animated);

  var _default = _component.default.extend({
    moment: (0, _service.inject)(),
    init: function init() {
      this._super.apply(this, arguments);

      this.chart = null;
      this.data = [];
      this.preview_data = [];
      this.preview_series = null;
      this.series1 = null;
      this.series2 = null;
      this.series3 = null;
      this.series1ready = false;
      this.series2ready = false;
      this.series3ready = false;
      this.chartIsReady = false;
      this.dynmode = false;
      this.dateAxis = null;
      this.begin_time = 0;
      this.end_time = 0;
      this.flip_rainfall = false;
      this.chartLoadingIndicator = null;
      this.chartLoadingIndicatorInterval = null;
    },
    scheduleDynMode: function scheduleDynMode() {
      (0, _runloop.later)(this, function () {
        this.set('dynmode', true);
        console.log("ready...");
      }, 1000);
    },
    showLoadingIndicator: function showLoadingIndicator() {
      var loadingIndicator = this.get('chartLoadingIndicator');
      var indicatorInterval = this.get('chartLoadingIndicatorInterval');
      var chart = this.get('chart');

      if (!loadingIndicator) {
        loadingIndicator = chart.tooltipContainer.createChild(am4core.Container);
        loadingIndicator.background.fill = am4core.color("#fff");
        loadingIndicator.background.fillOpacity = 0.8;
        loadingIndicator.width = am4core.percent(100);
        loadingIndicator.height = am4core.percent(100);
        loadingIndicator.hoverable = true;
        loadingIndicator.clickale = true;
        var loadingIndicatorLabel = loadingIndicator.createChild(am4core.Label);
        loadingIndicatorLabel.text = "Loading site data...";
        loadingIndicatorLabel.align = "center";
        loadingIndicatorLabel.valign = "middle";
        loadingIndicatorLabel.fontSize = 18;
        loadingIndicatorLabel.dy = 50;
        var loadingIndicatorIcon = loadingIndicator.createChild(am4core.Image);
        loadingIndicatorIcon.href = '/assets/images/CSIRO_Logo.svg';
        loadingIndicatorIcon.align = "center";
        loadingIndicatorIcon.valign = "middle";
        loadingIndicatorIcon.horizontalCenter = "middle";
        loadingIndicatorIcon.verticalCenter = "middle";
        loadingIndicatorIcon.scale = 1.2;
        loadingIndicator.hide(0);
        loadingIndicator.show();
        clearInterval(indicatorInterval);

        if (!indicatorInterval) {
          indicatorInterval = setInterval(function () {
            if (chart.isDisposed()) {
              clearInterval(indicatorInterval);
              return;
            }

            loadingIndicatorIcon.animate([{
              to: 1.3,
              property: "scale"
            }, {
              to: 1.2,
              property: "scale"
            }], 1000);
          }, 1200, am4core.ease.quadInOut);
        }
      }

      loadingIndicator.show();
      chart.cursor.interactionsEnabled = false;
      this.set('chartLoadingIndicator', loadingIndicator);
    },
    hideLoadingIndicator: function hideLoadingIndicator() {
      var loadingIndicator = this.get('chartLoadingIndicator');
      var loadingIndicatorInterval = this.get('chartLoadingIndicatorInterval');
      var chart = this.get('chart');
      clearInterval(loadingIndicatorInterval);
      loadingIndicator.hide();
      chart.cursor.interactionsEnabled = true;
    },
    seriesReadyChanged: (0, _object.observer)("chartIsReady", "series1ready", "series2ready", "series3ready", function () {
      var chartIsReady = this.chartIsReady;
      var series1ready = this.series1ready;
      var series2ready = this.series2ready;
      var series3ready = this.series3ready;

      if (chartIsReady && series1ready && series2ready && series3ready) {
        (0, _runloop.debounce)(this, "scheduleDynMode", 100);
        this.hideLoadingIndicator();
      }
    }),
    getNewData: function getNewData(args) {
      var m = this.moment;
      var begin_time = args.begin_time,
          end_time = args.end_time;
      this.set('dynmode', false);
      this.set('series1ready', false);
      this.set('series2ready', false);
      this.set('series3ready', false);
      console.log("not ready");
      this.showLoadingIndicator();
      this.set('begin_time', begin_time);
      this.set('end_time', end_time);
      var duration_millis = end_time - begin_time;
      var aggregate_seconds = 0;

      if (duration_millis > 2678400000) {
        var duration_seconds = (duration_millis - duration_millis % 1000) / 1000;
        aggregate_seconds = (duration_seconds - duration_seconds % 1000) / 1000;
      }

      var begin_date = m.moment(begin_time);
      var end_date = m.moment(end_time);
      var dateAxis = this.dateAxis;
      var self = this;
      this.site.getObservations(begin_date, end_date, aggregate_seconds).then(function (ap) {
        var series1 = self.get('series1');
        var series2 = self.get('series2');
        var series3 = self.get('series3');

        if (ap.length < 1) {
          var empty1 = [];
          self.set('data', empty1);
          series1.data = empty1;
          series2.data = empty1;
          series3.data = empty1;
          return;
        } //let preview_series = self.get("preview_series");


        self.set('data', ap);

        if (aggregate_seconds > 0) {
          dateAxis.baseInterval = {
            timeUnit: "second",
            count: aggregate_seconds
          };
        } else {
          dateAxis.baseInterval = {
            timeUnit: "minute",
            count: 60
          };
        }

        series1.data = ap;
        series2.data = ap;
        series3.data = ap;
        var first_time = ap[0].time;
        var last_time = ap[ap.length - 1].time; //preview_series.hide();

        self.dateAxis.zoomToDates(first_time, last_time);
        self.set('begin_time', first_time.getTime());
        self.set('end_time', last_time.getTime()); //self.set('begin_time', begin_date.valueOf());
        //self.set('end_time', end_date.valueOf());
        //self.chart.invalidate();
      });
    },
    dateAxisChanged: function dateAxisChanged(ev) {
      var dynmode = this.dynmode;

      if (!dynmode) {
        return;
      }

      var old_begin_time = this.begin_time;
      var old_end_time = this.end_time;
      var new_begin_time = ev.target.minZoomed;
      var new_end_time = ev.target.maxZoomed;

      if (old_begin_time !== new_begin_time || old_end_time !== new_end_time) {
        //let begin_time_offset = (new_begin_time - old_begin_time)/1000;
        //let end_time_offset = (new_end_time - old_end_time)/1000;
        //console.log("B: " + (begin_time_offset/60) + " mins (" + begin_time_offset + " seconds)");
        //console.log("E: " + (end_time_offset/60) + " mins (" + end_time_offset + " seconds)");
        (0, _runloop.debounce)(this, 'getNewData', {
          begin_time: new_begin_time,
          end_time: new_end_time
        }, 20);
      }
    },
    chartReady: function chartReady(ev) {
      var m = this.moment;
      var preview_series = this.preview_series;
      var series1 = this.series1;
      var series2 = this.series2;
      var series3 = this.series3;
      var dateAxis = this.dateAxis;
      var chart = this.chart;
      var site = this.site;
      var self = this;
      this.set('dynmode', false);
      this.set('series1ready', false);
      this.set('series2ready', false);
      this.set('series3ready', false);
      var initial_display_from_date = m.utc();
      var lastObservation = null;
      site.getLastObservations(1, 4, true).then(function (ap) {
        if (ap.length > 0) {
          lastObservation = ap[0];
          initial_display_from_date = m.moment(lastObservation.time).utc(); //console.info("Got known data date is: "+ap[0].time)
        }

        return initial_display_from_date;
      }).then(function (from_date) {
        return site.getAllDaily(from_date, 3, true, ["soil_moist"]);
      }).then(function (ap) {
        if (lastObservation) {
          ap.pushObject(lastObservation);
        }

        self.set('preview_data', ap);
        preview_series.data = ap;
        preview_series.hide();
        return initial_display_from_date;
      }).then(function (from_date) {
        return site.get6Months(from_date, 4, true);
      }).then(function (ap) {
        if (ap.length < 1) {
          var empty1 = [];
          self.set('data', empty1);
          series1.data = empty1;
          series2.data = empty1;
          series3.data = empty1;
          return;
        }

        var first_time = ap[0].time;
        var last_time = ap[ap.length - 1].time;
        var count = ap.length;
        var msGap;

        if (count > 2) {
          msGap = (last_time - first_time) / (count - 1);
        } else {
          msGap = last_time - first_time;
        }

        if (lastObservation) {
          ap.pushObject(lastObservation);
          last_time = lastObservation.time;
        } //console.log("First Time: "+first_time);
        //console.log("Last Time: "+last_time);


        self.set('data', ap);
        dateAxis.baseInterval = {
          timeUnit: "second",
          count: msGap / 1000.0
        };
        series1.data = ap;
        series2.data = ap;
        series3.data = ap;
        dateAxis.zoomToDates(first_time, last_time);
        self.set('begin_time', first_time.getTime());
        self.set('end_time', last_time.getTime()); //series.invalidate();
      }).then(site.getCalibrations.bind(site)).then(function (ap) {
        var dates = [];
        ap.forEach(function (cal) {
          var date = cal.get('date_UTC');

          if (!dates.includes(date)) {
            dates.push(date);
          }
        });
        var scrollbarXAxis = chart.scrollbarX.scrollbarChart.xAxes.getIndex(0);
        dates.forEach(function (date) {
          // create a new guide
          var guide = dateAxis.axisRanges.create();
          var d = new Date(date);
          guide.date = d;
          guide.grid.stroke = "#222";
          guide.grid.strokeWidth = 2;
          guide.grid.strokeOpacity = 1;
          guide.grid.strokeDasharray = "4 2";
          guide.grid.above = true;
          guide.label.inside = true;
          guide.label.valign = "top";
          guide.label.text = "Calibrated";
          guide.label.textAlign = "middle";
          guide.label.rotation = -90;
          guide.label.dx = -10;
          var previewGuide = scrollbarXAxis.axisRanges.create();
          previewGuide.date = d;
          previewGuide.grid.stroke = guide.grid.stroke;
          previewGuide.grid.strokeOpacity = guide.grid.strokeOpacity;
          previewGuide.grid.strokeDasharray = guide.grid.strokeDasharray;
          previewGuide.grid.above = guide.grid.above;
          previewGuide.grid.strokeWidth = guide.grid.strokeWidth;
        });
      }).then(function () {
        (0, _runloop.later)(self, 'set', 'chartIsReady', true, 800);
      }).catch(function (e) {
        console.log("Initial load of site data failed or was cancelled.");
      });
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.set('dynmode', false);
      var chart = am4core.create(this.elementId, am4charts.XYChart); //chart.paddingRight = 20;

      chart.preloader.disabled = true;
      var init_data = this.data;
      var init_preview_data = this.preview_data; //chart.dateFormatter.inputDateFormat = "yyyy-MM-dd'T'HH:mm:ss'Z'";

      chart.dateFormatter.dateFormat = "yyyy-MM-dd HH:mm";
      var dateAxisChanged = this.dateAxisChanged;
      var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      dateAxis.events.on("selectionextremeschanged", dateAxisChanged.bind(this));
      dateAxis.renderer.grid.template.location = 0;
      dateAxis.renderer.labels.template.rotation = -66;
      dateAxis.renderer.minGridDistance = 18;
      dateAxis.baseInterval = {
        timeUnit: "minute",
        count: 60
      }; //dateAxis.groupData = false;

      dateAxis.tooltipDateFormat = "yyyy-MM-dd";
      dateAxis.title.text = "Timestamp (UTC)";
      this.set('dateAxis', dateAxis);
      var dateAxisTooltip = dateAxis.tooltip;
      dateAxisTooltip.background.fill = am4core.color("#120f1d");
      dateAxisTooltip.background.strokeWidth = 1;
      dateAxisTooltip.background.cornerRadius = 3;
      dateAxisTooltip.background.pointerLength = 10;
      var dropShadow = new am4core.DropShadowFilter();
      dropShadow.dy = 1;
      dropShadow.dx = 1;
      dropShadow.opacity = 0.5;
      dateAxisTooltip.filters.push(dropShadow);
      var label = chart.createChild(am4core.Label);
      label.text = "Click to turn a variable on or off";
      label.fontSize = 16;
      label.align = "center";
      var valueAxis1 = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis1.title.text = "Soil Moisture (percentage)\nEffective Measurement Depth (cm)";
      valueAxis1.min = 0;
      valueAxis1.tooltip.disabled = false;
      valueAxis1.renderer.line.strokeOpacity = 1;
      valueAxis1.renderer.line.strokeWidth = 2; //valueAxis1.renderer.minWidth = 35;

      var rainfallAxis = chart.yAxes.push(new am4charts.ValueAxis());
      rainfallAxis.min = 0;
      rainfallAxis.tooltip.disabled = false;

      if (this.flip_rainfall) {
        rainfallAxis.renderer.inversed = true;
      }

      rainfallAxis.renderer.line.strokeOpacity = 1;
      rainfallAxis.renderer.line.strokeWidth = 2;
      rainfallAxis.renderer.opposite = true;
      rainfallAxis.title.text = "Rainfall (mm)"; // //rainfallAxis.title.rotatation = 180;
      //rainfallAxis.renderer.minWidth = 35;
      // let valueAxis3 = chart.yAxes.push(new am4charts.ValueAxis());
      // valueAxis3.min = 0;
      // valueAxis3.tooltip.disabled = false;
      // valueAxis3.renderer.line.strokeOpacity = 1;
      // valueAxis3.renderer.line.strokeWidth = 2;
      // valueAxis3.renderer.opposite = true;
      // //valueAxis3.renderer.minWidth = 35;

      var series1 = chart.series.push(new am4charts.LineSeries());
      series1.data = init_data;
      series1.yAxis = valueAxis1;
      series1.dataFields.dateX = "time";
      series1.dataFields.valueY = "soil_moist_filtered";
      series1.name = "Soil Moisture";
      series1.strokeWidth = 2;
      series1.minBulletDistance = 0;
      series1.connect = false; //series1.baseInterval = { timeUnit: "minute", count: 60 };

      series1.autoGapCount = 3.0;
      series1.showOnInit = false;
      series1.stroke = "#3cca5e";
      series1.fill = "#2de968"; //valueAxis1.renderer.line.stroke = series1.stroke;

      series1.tooltipText = "Time: [bold]{dateX}[/]\nSoil Moisture [bold]{valueY}[/]";
      series1.events.on("validated", function () {
        this.set("series1ready", true);
      }, this);
      this.set('series1', series1);
      var series2 = chart.series.push(new am4charts.LineSeries());
      series2.data = init_data;
      series2.yAxis = valueAxis1;
      series2.dataFields.dateX = "time";
      series2.dataFields.valueY = "depth_filtered";
      series2.name = "Effective Measurement Depth";
      series2.strokeWidth = 2;
      series2.minBulletDistance = 0; //series2.baseInterval = { timeUnit: "minute", count: 60 };

      series2.connect = false;
      series2.autoGapCount = 3.0;
      series2.showOnInit = false;
      series2.stroke = "#6e438f";
      series2.fill = "#80588f";
      series2.tooltipText = "Time: [bold]{dateX}[/]\nEffective Depth [bold]{valueY}[/]cm";
      series2.visible = false; //rainfallAxis.renderer.line.stroke = series2.stroke;

      series2.events.on("validated", function () {
        this.set("series2ready", true);
      }, this);
      this.set('series2', series2);
      var series3 = chart.series.push(new am4charts.LineSeries());
      series3.data = init_data;
      series3.yAxis = rainfallAxis;
      series3.dataFields.dateX = "time";
      series3.dataFields.valueY = "rainfall";
      series3.name = "Rainfall";
      series3.strokeWidth = 2;
      series3.minBulletDistance = 0; //series3.baseInterval = { timeUnit: "minute", count: 60 };

      series3.connect = false;
      series3.autoGapCount = 3.0;
      series3.showOnInit = false;
      series3.stroke = "#286fff";
      series3.fill = "#7ba5ff";
      series3.tooltipText = "Time: [bold]{dateX}[/]\nRainfall [bold]{valueY}[/]mm"; //rainfallAxis.renderer.line.stroke = series3.stroke;
      //series.tooltipText = "{valueY.value}";

      series3.events.on("validated", function () {
        this.set("series3ready", true);
      }, this);
      this.set('series3', series3);
      var preview_series = chart.series.push(new am4charts.LineSeries());
      preview_series.data = init_preview_data;
      preview_series.hiddenInLegend = true; //preview_series.xAxis = previewDateAxis;

      preview_series.dataFields.dateX = "time";
      preview_series.dataFields.valueY = "soil_moist_filtered";
      preview_series.minBulletDistance = 0;
      preview_series.connect = false;
      preview_series.autoGapCount = 3.0;
      preview_series.showOnInit = false; //preview_series.xAxis = previewDateAxis;
      //preview_series.hide();

      this.set("preview_series", preview_series);
      chart.scrollbarX = new am4charts.XYChartScrollbar();
      chart.scrollbarX.orientation = "horizontal";
      chart.scrollbarX.updateWhileMoving = false;
      chart.scrollbarX.parent = chart.topAxesContainer;
      chart.scrollbarX.series.push(preview_series);
      var scrollbarXAxis = chart.scrollbarX.scrollbarChart.xAxes.getIndex(0);
      scrollbarXAxis.baseInterval = {
        timeUnit: "hour",
        count: 48
      };
      scrollbarXAxis.renderer.labels.template.rotation = 0;
      scrollbarXAxis.renderer.minGridDistance = 40;
      scrollbarXAxis.renderer.inside = false; //chart.scrollbarX.scrollbarChart.yAxes.push(new am4charts.ValueAxis());
      //let preview_series = chart.scrollbarX.series.push(new am4charts.LineSeries());
      //chart.scrollbarX.series.push(preview_series);

      chart.cursor = new am4charts.XYCursor(); // Make bullets grow on hover
      // let bullet = series.bullets.push(new am4charts.CircleBullet());
      // bullet.circle.strokeWidth = 2;
      // bullet.circle.radius = 4;
      // bullet.circle.fill = am4core.color("#fff");
      //
      // let bullethover = bullet.states.create("hover");
      // bullethover.properties.scale = 1.3;
      // Create vertical scrollbar and place it before the value axis
      // chart.scrollbarY = new am4core.Scrollbar();
      // chart.scrollbarY.parent = chart.leftAxesContainer;
      // chart.scrollbarY.toBack();
      // chart.scrollbarY2 = new am4core.Scrollbar();
      // chart.scrollbarY2.parent = chart.rightAxesContainer;
      // chart.scrollbarY2.toFront();
      // Create a horizontal scrollbar with preview and place it underneath the date axis

      chart.legend = new am4charts.Legend();
      this.set('chart', chart);
      this.showLoadingIndicator();
      var chartReady = this.chartReady;
      chart.events.on("ready", chartReady.bind(this));
    },
    willDestroyElement: function willDestroyElement() {
      if (this.chart) {
        this.chart.dispose();
      }

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});