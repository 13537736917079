define("cosmoz-ui/components/models-table/themes/ember-paper/row-select-all-checkbox", ["exports", "ember-models-table/components/models-table/themes/ember-paper/row-select-all-checkbox"], function (_exports, _rowSelectAllCheckbox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _rowSelectAllCheckbox.default;
    }
  });
});