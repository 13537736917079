define("cosmoz-ui/templates/manyviz", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Sb3E1+a/",
    "block": "[[[8,[39,0],null,[[\"@allStations\",\"@store\"],[[30,0,[\"model\"]],[30,0,[\"store\"]]]],[[\"default\"],[[[],[]]]]],[1,\"\\n\"],[46,[28,[37,2],null,null],null,null,null]],[],false,[\"cosmoz-many-viz\",\"component\",\"-outlet\"]]",
    "moduleName": "cosmoz-ui/templates/manyviz.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});