define("cosmoz-ui/models/intensity", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    time: (0, _model.attr)('isodate'),
    intensity: (0, _model.attr)(),
    bad_data_flag: (0, _model.attr)(),
    mean: (0, _model.attr)(),
    mean_intensity: (0, _model.attr)(),
    mean_bad_data_flag: (0, _model.attr)()
  });

  _exports.default = _default;
});