define("cosmoz-ui/controllers/application", ["exports", "@ember/controller", "cosmoz-ui/mixins/with-root", "@ember/service"], function (_exports, _controller, _withRoot, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend(_withRoot.default, {
    session: (0, _service.inject)(),
    //the session service comes from ember-simple-auth
    currentUser: (0, _service.inject)(),
    actions: {
      invalidateSession: function invalidateSession() {
        this.session.invalidate();
      }
    }
  });

  _exports.default = _default;
});