define("cosmoz-ui/templates/admin/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "69AttOVG",
    "block": "[[[46,[28,[37,1],null,null],null,null,null],[1,\"\\n\"],[10,\"h1\"],[12],[1,\"Example admin page. Requires Auth to get here.\"],[13]],[],false,[\"component\",\"-outlet\"]]",
    "moduleName": "cosmoz-ui/templates/admin/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});