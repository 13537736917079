define("cosmoz-ui/authenticators/oauth2", ["exports", "cosmoz-ui/authenticators/oauth2-auth-code-pkce", "cosmoz-ui/config/environment"], function (_exports, _oauth2AuthCodePkce, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //import OAuth2PasswordGrant from 'ember-simple-auth/authenticators/oauth2-password-grant';
  var _default = _oauth2AuthCodePkce.default.extend(_environment.default.auth);

  _exports.default = _default;
});