define("cosmoz-ui/templates/components/api-try-me", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "xe2XgM9B",
    "block": "[[[10,1],[12],[1,[30,0,[\"HTTPMethod\"]]],[13],[1,\" \"],[10,\"em\"],[12],[1,[30,0,[\"endpointURL\"]]],[13],[10,\"br\"],[12],[13],[1,\"\\n\"],[18,1,[[30,0]]],[10,\"br\"],[12],[13],[1,\"\\n\"],[10,0],[12],[1,\"\\n    \"],[10,1],[14,0,\"try_me_built_url_label\"],[12],[1,\"Built URL:\"],[13],[1,\" \"],[10,1],[14,0,\"try_me_built_url\"],[12],[1,[30,0,[\"substitutedURL\"]]],[13],[10,\"br\"],[12],[13],[1,\"\\n\"],[10,\"button\"],[15,\"onclick\",[28,[37,1],[[30,0],\"doTryMe\"],null]],[15,\"disabled\",[30,0,[\"tryIsDisabled\"]]],[12],[1,\"Try Built URL\"],[13],[41,[30,0,[\"showResultBox\"]],[[[1,\" \"],[10,\"button\"],[15,\"onclick\",[28,[37,1],[[30,0],\"closeResultBox\"],null]],[12],[1,\"Close Results\"],[13]],[]],null],[1,\"\\n\"],[13],[1,\"\\n\"],[41,[30,0,[\"showResultBox\"]],[[[10,0],[14,0,\"try_me_text_area wordwrap\"],[12],[10,\"pre\"],[14,0,\"wordwrap\"],[12],[1,[30,0,[\"tryMeResults\"]]],[13],[13],[1,\"\\n\"]],[]],null]],[\"&default\"],false,[\"yield\",\"action\",\"if\"]]",
    "moduleName": "cosmoz-ui/templates/components/api-try-me.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});