define("cosmoz-ui/serializers/station", ["exports", "@ember-data/serializer/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rest.default.extend({
    normalize: function normalize(model, hash, prop) {
      var site_no = hash.site_no;

      if (site_no) {
        hash.id = site_no;
      }

      return this._super.apply(this, arguments);
    },
    _normalizeResponse: function _normalizeResponse(store, primaryModelClass, payload, id, requestType, isSingle) {
      if (isSingle) {
        if (payload.hasOwnProperty("site_no")) {
          payload = {
            "station": payload
          };
        }

        return this._super(store, primaryModelClass, payload, id, requestType, false);
      }

      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});