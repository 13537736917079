define("cosmoz-ui/models/station", ["exports", "@ember-data/model", "ember-data", "cosmoz-ui/config/environment", "@ember/service", "@ember/object", "@ember/object/proxy"], function (_exports, _model, _emberData, _environment, _service, _object, _proxy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var APP = _environment.default.APP;

  if (_environment.default.environment === 'development') {// ...
  }

  var _default = _model.default.extend({
    APP: APP,
    moment: (0, _service.inject)(),
    site_no: (0, _model.attr)(),
    site_name: (0, _model.attr)(),
    tube_type: (0, _model.attr)(),
    network: (0, _model.attr)(),
    imei: (0, _model.attr)(),
    sat_data_select: (0, _model.attr)(),
    latitude: (0, _model.attr)(),
    longitude: (0, _model.attr)(),
    altitude: (0, _model.attr)(),
    installation_date: (0, _model.attr)('isolocal'),
    site_description: (0, _model.attr)(),
    calibration_type: (0, _model.attr)(),
    timezone: (0, _model.attr)(),
    site_photo_name: (0, _model.attr)(),
    status: (0, _model.attr)(),
    ref_pressure: (0, _model.attr)(),
    ref_intensity: (0, _model.attr)(),
    cutoff_rigidity: (0, _model.attr)(),
    elev_scaling: (0, _model.attr)(),
    latit_scaling: (0, _model.attr)(),
    scaling: (0, _model.attr)(),
    beta: (0, _model.attr)(),
    n0_cal: (0, _model.attr)(),
    bulk_density: (0, _model.attr)(),
    lattice_water_g_g: (0, _model.attr)(),
    soil_organic_matter_g_g: (0, _model.attr)(),
    nmdb: (0, _model.attr)(),
    hydroinnova_serial_no: (0, _model.attr)(),
    contact: (0, _model.attr)(),
    email: (0, _model.attr)(),
    observations: (0, _model.hasMany)('observation', {
      inverse: null
    }),
    calibrations: (0, _model.hasMany)('calibration', {
      async: true
    }),
    annotations: (0, _model.hasMany)('annotation', {
      async: true
    }),
    _lastdatapromise: null,
    is_calibrated: (0, _object.computed)("calibration_type", function () {
      var c_type = this.calibration_type;
      return c_type !== "U";
    }),
    // large_photo_name: computed("site_photo_name", function () {
    //   let s = this.site_photo_name;
    //   return s.replace(".jp", "_Large.jp")
    // }),
    selflink: (0, _object.computed)("APP.BACKEND_HOST", function () {
      var selfAdaptor = this.store.adapterFor(this.constructor.modelName);
      var site_no = this.site_no;
      return selfAdaptor.urlForFindRecord(site_no, this.constructor.modelName);
    }),
    obslink: (0, _object.computed)("APP.BACKEND_HOST", function () {
      var obsAdaptor = this.store.adapterFor("observation");
      var site_no = this.site_no;
      return obsAdaptor.urlForQuery({
        filter: {
          station_no: site_no
        }
      }, "observation");
    }),
    callink: (0, _object.computed)("APP.BACKEND_HOST", function () {
      var calAdaptor = this.store.adapterFor("calibration");
      return calAdaptor.getQueryUrlWithParams("calibration", {
        station_no: this.site_no
      });
    }),
    appendQueryParams: function appendQueryParams(url, params) {
      var queryParamDelimiter = url.indexOf('?') > -1 ? '&' : '?';
      url += "".concat(queryParamDelimiter).concat(new URLSearchParams(params).toString());
      return url;
    },
    calibrationDataLink: (0, _object.computed)("callink", function () {
      return this.appendQueryParams(this.callink, {
        format: 'application/json'
      });
    }),
    calibrationDataTextLink: (0, _object.computed)("callink", function () {
      return this.appendQueryParams(this.callink, {
        format: 'text/plain'
      });
    }),
    calibrationDataCSVLink: (0, _object.computed)("callink", function () {
      return this.appendQueryParams(this.callink, {
        format: 'text/csv'
      });
    }),
    calculatedSiteValuesTextLink: (0, _object.computed)("selflink", function () {
      return this.appendQueryParams(this.selflink, {
        format: 'text/plain'
      });
    }),
    rawDataLink: (0, _object.computed)("obslink", function () {
      return this.appendQueryParams(this.obslink, {
        format: 'application/json',
        processing_level: 0
      });
    }),
    rawDataTextLink: (0, _object.computed)("obslink", function () {
      return this.appendQueryParams(this.obslink, {
        format: 'text/plain',
        processing_level: 0,
        excel_compat: true
      });
    }),
    rawDataCSVLink: (0, _object.computed)("obslink", function () {
      return this.appendQueryParams(this.obslink, {
        format: 'text/csv',
        processing_level: 0,
        excel_compat: true
      });
    }),
    level1Link: (0, _object.computed)("obslink", function () {
      return this.appendQueryParams(this.obslink, {
        format: 'application/json',
        processing_level: 1
      });
    }),
    level1TextLink: (0, _object.computed)("obslink", function () {
      return this.appendQueryParams(this.obslink, {
        format: 'text/plain',
        processing_level: 1,
        excel_compat: true
      });
    }),
    level1CSVLink: (0, _object.computed)("obslink", function () {
      return this.appendQueryParams(this.obslink, {
        format: 'text/csv',
        processing_level: 1,
        excel_compat: true
      });
    }),
    level2Link: (0, _object.computed)("obslink", function () {
      var obslink = this.obslink;
      return "" + obslink + "?processing_level=2&format=application/json";
    }),
    level2TextLink: (0, _object.computed)("obslink", function () {
      var obslink = this.obslink;
      return "" + obslink + "?processing_level=2&format=text/plain&excel_compat=true";
    }),
    level2CSVLink: (0, _object.computed)("obslink", function () {
      var obslink = this.obslink;
      return "" + obslink + "?processing_level=2&format=text/csv&excel_compat=true";
    }),
    level3Link: (0, _object.computed)("obslink", function () {
      var obslink = this.obslink;
      return "" + obslink + "?processing_level=3&format=application/json";
    }),
    level3TextLink: (0, _object.computed)("obslink", function () {
      var obslink = this.obslink;
      return "" + obslink + "?processing_level=3&format=text/plain&excel_compat=true";
    }),
    level3CSVLink: (0, _object.computed)("obslink", function () {
      var obslink = this.obslink;
      return "" + obslink + "?processing_level=3&format=text/csv&excel_compat=true";
    }),
    level4Link: (0, _object.computed)("obslink", function () {
      var obslink = this.obslink;
      return "" + obslink + "?processing_level=4&format=application/json";
    }),
    level4TextLink: (0, _object.computed)("obslink", function () {
      var obslink = this.obslink;
      return "" + obslink + "?processing_level=4&format=text/plain&excel_compat=true";
    }),
    level4CSVLink: (0, _object.computed)("obslink", function () {
      var obslink = this.obslink;
      return "" + obslink + "?processing_level=4&format=text/csv&excel_compat=true";
    }),
    lastLevel4DataPoint: (0, _object.computed)('_lastdatapromise', function () {
      var lastdatapromise = this._lastdatapromise;

      if (lastdatapromise == null) {
        var p = this.getLastObservations(1, 4, true);
        this.set('_lastdatapromise', p);
        return p;
      } else {
        return lastdatapromise;
      }
    }),
    lastDataDatetime: (0, _object.computed)('lastLevel4DataPoint', function () {
      var lastData = this.lastLevel4DataPoint;
      var p = lastData.then(function (ap) {
        if (ap.length < 1) {
          return null;
        } else {
          return ap[0].time;
        }
      });
      return _emberData.default.PromiseObject.create({
        promise: p
      });
    }),
    lastSoilMoisture: (0, _object.computed)('lastLevel4DataPoint', function () {
      var lastData = this.lastLevel4DataPoint;
      var p = lastData.then(function (ap) {
        if (ap.length < 1) {
          return null;
        } else {
          return ap[0].soil_moist_filtered;
        }
      });
      return _emberData.default.PromiseObject.create({
        promise: p
      });
    }),
    dynamicStatus: (0, _object.computed)('status', 'lastDataDatetime', function () {
      var status = this.status;

      if (status !== "live") {
        return _proxy.default.create({
          content: status
        });
      }

      var hours_24 = 1000 * 60 * 60 * 24; //in ms

      var hours_48 = hours_24 * 2;
      var m = this.moment;
      var now = m.utc();
      var p = this.lastDataDatetime.then(function (dt) {
        var diff = now.diff(m.moment(dt).utc());

        if (hours_48 <= diff) {
          return "offline";
        } else if (hours_24 <= diff) {
          return "delayed";
        }

        return status;
      });
      return _emberData.default.PromiseObject.create({
        promise: p,
        content: "waiting"
      });
    }),
    getDailyStats: function getDailyStats(from_date, to_date, level, marshall, propertyFilter) {
      var site_no = this.site_no;
      var m = this.moment;

      if (from_date === undefined || from_date === null) {
        from_date = m.moment('1970-01-01').startOf('day');
      } else {
        from_date = m.moment(from_date).startOf('day');
      }

      if (to_date === undefined || to_date === null) {
        to_date = m.utc().startOf('day');
      } else {
        to_date = m.moment(to_date).startOf('day');
      }

      var f = {
        station_no: site_no,
        startdate: from_date.format(),
        enddate: to_date.format()
      };

      if (level === undefined || level < 0) {
        level = 4;
      }

      f.processing_level = level;

      if (marshall === undefined) {
        marshall = true;
      }

      if (marshall || propertyFilter === undefined) {//No property filter
      } else {
        f.property_filter = propertyFilter;
      }

      var marshall_raw1 = function marshall_raw1(o) {
        return {
          time: o.get('time'),
          count: o.get('mean_count')
        };
      };

      var marshall_2 = function marshall_2(o) {
        return {
          time: o.get('time'),
          corr_count: o.get('mean_corr_count')
        };
      };

      var marshall_3 = function marshall_3(o) {
        return {
          time: o.get('time'),
          soil_moist_filtered: o.get('mean_soil_moist'),
          depth_filtered: o.get('mean_effective_depth')
        };
      };

      var marshall_4 = function marshall_4(o) {
        return {
          time: o.get('time'),
          soil_moist_filtered: o.get('mean_soil_moist_filtered'),
          depth_filtered: o.get('mean_depth_filtered')
        };
      };

      return this.store.query('daily', f).then(function (obs) {
        if (!marshall) {
          return obs;
        }

        var marshall_f;

        if (level >= 4) {
          marshall_f = marshall_4;
        } else if (level === 3) {
          marshall_f = marshall_3;
        } else if (level === 2) {
          marshall_f = marshall_2;
        } else {
          marshall_f = marshall_raw1;
        }

        return obs.map(marshall_f);
      });
    },
    getAllDaily: function getAllDaily(to_date, level, marshall, propertyFilter) {
      var installation_date_str = this.installation_date;
      var m = this.moment;
      var from_date;
      var year_ago = m.moment(to_date).subtract(1, 'year');

      if (installation_date_str) {
        var install_date = m.moment(installation_date_str).utc();

        if (install_date.isBefore(year_ago)) {
          from_date = install_date;
        } else {
          from_date = year_ago;
        }
      } else {
        from_date = year_ago;
      }

      return this.getDailyStats(from_date, to_date, level, marshall, propertyFilter);
    },
    get24hAggregate: function get24hAggregate(to_date, level, alignDay, marshall, propertyFilter) {
      var site_no = this.site_no;
      var installation_date_str = this.installation_date;
      var m = this.moment;

      if (alignDay === undefined) {
        alignDay = false;
      }

      if (to_date === undefined || to_date === null) {
        to_date = m.utc().startOf('day');
      } else {
        if (alignDay) {
          to_date = m.moment(to_date).startOf('day');
        }
      }

      var f = {
        station_no: site_no,
        aggregate: "48h",
        enddate: to_date.format()
      };

      if (level === undefined || level < 0) {//#fallback to default processing_level (4)
      } else {
        f.processing_level = level;
      }

      if (marshall === undefined) {
        marshall = true;
      }

      if (marshall || propertyFilter === undefined) {//No property filter
      } else {
        f.property_filter = propertyFilter;
      }

      var from_date;
      var year_ago = m.moment(to_date).subtract(1, 'year');

      if (installation_date_str) {
        var install_date = m.moment(installation_date_str).utc();

        if (install_date.isBefore(year_ago)) {
          from_date = install_date;
        } else {
          from_date = year_ago;
        }
      } else {
        from_date = year_ago;
      }

      f.startdate = from_date.format();
      return this.store.query('observation', f).then(function (obs) {
        if (!marshall) {
          return obs;
        }

        return obs.map(function (o) {
          return {
            time: o.get('time'),
            soil_moist_filtered: o.get('mean_soil_moist_filtered'),
            depth_filtered: o.get('mean_depth_filtered'),
            rainfall: o.get('mean_rainfall')
          };
        });
      });
    },
    getAllIntensitiesDaily: function getAllIntensitiesDaily(to_date, alignDay, marshall, propertyFilter) {
      var site_no = this.site_no;
      var installation_date_str = this.installation_date;
      var m = this.moment;

      if (alignDay === undefined) {
        alignDay = false;
      }

      if (to_date === undefined || to_date === null) {
        to_date = m.utc().startOf('day');
      } else {
        if (alignDay) {
          to_date = m.moment(to_date).startOf('day');
        }
      }

      var f = {
        station_no: site_no,
        aggregate: "48h",
        enddate: to_date.format()
      };

      if (marshall === undefined) {
        marshall = true;
      }

      if (marshall || propertyFilter === undefined) {//No property filter
      } else {
        f.property_filter = propertyFilter;
      }

      var from_date;
      var year_ago = m.moment(to_date).subtract(1, "year");

      if (installation_date_str) {
        var install_date = m.moment(installation_date_str).utc();

        if (install_date.isBefore(year_ago)) {
          from_date = install_date;
        } else {
          from_date = year_ago;
        }
      } else {
        from_date = year_ago;
      }

      f.startdate = from_date.format();
      return this.store.query('intensity', f).then(function (obs) {
        if (!marshall) {
          return obs;
        }

        return obs.map(function (o) {
          return {
            time: o.get('time'),
            intensity: o.get('mean_intensity')
          };
        });
      });
    },
    getAMonth: function getAMonth(to_date, level, marshall, propertyFilter) {
      var site_no = this.site_no;
      var m = this.moment;

      if (to_date == null) {
        to_date = m.utc().startOf('day');
      }

      var from_date = m.moment(to_date).subtract(1, "month");
      var f = {
        station_no: site_no,
        startdate: from_date.format(),
        enddate: to_date.format()
      };

      if (level === undefined || level < 0) {//#fallback to default processing_level (4)
      } else {
        f.processing_level = level;
      }

      if (marshall === undefined) {
        marshall = true;
      }

      if (marshall || propertyFilter === undefined) {//No property filter
      } else {
        f.property_filter = propertyFilter;
      }

      return this.store.query('observation', f).then(function (obs) {
        if (!marshall) {
          return obs;
        }

        return obs.map(function (o) {
          return {
            time: o.get('time'),
            soil_moist_filtered: o.get('soil_moist_filtered'),
            depth_filtered: o.get('depth_filtered'),
            rainfall: o.get('rainfall')
          };
        });
      });
    },
    get6Months: function get6Months(to_date, level, marshall, propertyFilter) {
      var m = this.moment;

      if (to_date == null) {
        to_date = m.utc().startOf('day');
      }

      var from_date = m.moment(to_date).subtract(6, 'months');
      return this.getObservations(from_date, to_date, Math.ceil(31557600 / 2000), level, marshall, propertyFilter);
    },
    getAYear: function getAYear(to_date, level, marshall, propertyFilter) {
      var m = this.moment;

      if (to_date == null) {
        to_date = m.utc().startOf('day');
      }

      var from_date = m.moment(to_date).subtract(1, 'year');
      return this.getObservations(from_date, to_date, Math.ceil(31557600 / 2000), level, marshall, propertyFilter);
    },
    getIntensitiesYear: function getIntensitiesYear(to_date, marshall, propertyFilter) {
      var m = this.moment;

      if (to_date == null) {
        to_date = m.utc().startOf('day');
      }

      var from_date = m.moment(to_date).subtract(1, "year");
      return this.getIntensities(from_date, to_date, Math.ceil(31557600 / 2000), marshall, propertyFilter);
    },
    getObservations: function getObservations(from, to, aggregate_seconds, level, marshall, propertyFilter) {
      //console.debug(M);
      var site_no = this.site_no;
      var m = this.moment;
      var f = {
        station_no: site_no
      };

      if (from) {
        var from_str = m.moment(from).utc().format();
        f.startdate = from_str;
      }

      if (to) {
        var to_str = m.moment(to).utc().format();
        f.enddate = to_str;
      }

      if (aggregate_seconds) {
        f.aggregate = "" + aggregate_seconds + "s";
      }

      if (level === undefined || level < 0) {//#fallback to default processing_level (4)
      } else {
        f.processing_level = level;
      }

      if (marshall === undefined) {
        marshall = true;
      }

      if (marshall || propertyFilter === undefined) {//No property filter
      } else {
        f.property_filter = propertyFilter;
      }

      return this.store.query('observation', f).then(function (obs) {
        if (!marshall) {
          return obs;
        }

        if (aggregate_seconds) {
          return obs.map(function (o) {
            return {
              time: o.get('time'),
              soil_moist_filtered: o.get('mean_soil_moist_filtered'),
              depth_filtered: o.get('mean_depth_filtered'),
              rainfall: o.get('mean_rainfall')
            };
          });
        } else {
          return obs.map(function (o) {
            return {
              time: o.get('time'),
              soil_moist_filtered: o.get('soil_moist_filtered'),
              depth_filtered: o.get('depth_filtered'),
              rainfall: o.get('rainfall')
            };
          });
        }
      });
    },
    getLastObservations: function getLastObservations(count, level, marshall, propertyFilter) {
      //console.debug(M);
      var site_no = this.site_no;
      var f = {
        station_no: site_no,
        lastobservations: true
      };

      if (count === undefined || count < 1) {
        f.count = 1;
      } else {
        f.count = count;
      }

      if (level === undefined || level < 0) {//#fallback to default processing_level (4)
      } else {
        f.processing_level = level;
      }

      if (marshall === undefined) {
        marshall = true;
      }

      if (marshall || propertyFilter === undefined) {//No property filter
      } else {
        f.property_filter = propertyFilter;
      }

      return this.store.query('observation', f).then(function (obs) {
        if (!marshall) {
          return obs;
        }

        return obs.map(function (o) {
          return {
            time: o.get('time'),
            soil_moist_filtered: o.get('soil_moist_filtered'),
            depth_filtered: o.get('depth_filtered'),
            rainfall: o.get('rainfall')
          };
        });
      });
    },
    getIntensities: function getIntensities(from, to, aggregate_seconds, marshall, propertyFilter) {
      //console.debug(M);
      var site_no = this.site_no;
      var m = this.moment;
      var f = {
        station_no: site_no
      };

      if (from) {
        var from_str = m.moment(from).utc().format();
        f.startdate = from_str;
      }

      if (to) {
        var to_str = m.moment(to).utc().format();
        f.enddate = to_str;
      }

      if (aggregate_seconds) {
        f.aggregate = "" + aggregate_seconds + "s";
      }

      if (marshall === undefined) {
        marshall = true;
      }

      if (marshall || propertyFilter === undefined) {//No property filter
      } else {
        f.property_filter = propertyFilter;
      }

      return this.store.query('intensity', f).then(function (obs) {
        if (!marshall) {
          return obs;
        }

        return obs.map(function (o) {
          return {
            time: o.get('time'),
            intensity: o.get('mean_intensity')
          };
        });
      });
    },
    getCalibrations: function getCalibrations() {
      var site_no = this.site_no;
      return this.store.query('calibration', {
        station_no: site_no
      });
    },
    getAnnotations: function getAnnotations() {
      var site_no = this.site_no;
      return this.store.query('annotation', {
        station_no: site_no
      });
    }
  });

  _exports.default = _default;
});