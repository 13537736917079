define("cosmoz-ui/templates/sites/edit", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "1D0QXtyz",
    "block": "[[[10,0],[14,0,\"section\"],[12],[1,\"\\n  \"],[10,\"h1\"],[12],[1,\"Edit Station\"],[13],[1,\"\\n  \"],[10,\"br\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"tabset\"],[12],[1,\"\\n    \"],[10,\"input\"],[14,3,\"tabset\"],[14,1,\"tab1\"],[14,\"aria-controls\",\"details-tab\"],[14,\"checked\",\"\"],[14,4,\"radio\"],[12],[13],[1,\"\\n    \"],[10,\"label\"],[14,\"for\",\"tab1\"],[12],[1,\"Details\"],[13],[1,\"\\n\\n    \"],[10,\"input\"],[14,3,\"tabset\"],[14,1,\"tab2\"],[14,\"aria-controls\",\"calibrations-tab\"],[14,4,\"radio\"],[12],[13],[1,\"\\n    \"],[10,\"label\"],[14,\"for\",\"tab2\"],[12],[1,\"Calibrations\"],[13],[1,\"\\n\\n    \"],[10,\"input\"],[14,3,\"tabset\"],[14,1,\"tab3\"],[14,\"aria-controls\",\"annotations-tab\"],[14,4,\"radio\"],[12],[13],[1,\"\\n    \"],[10,\"label\"],[14,\"for\",\"tab3\"],[12],[1,\"Annotations\"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"tab-panels\"],[12],[1,\"\\n      \"],[10,\"section\"],[14,1,\"details-tab\"],[14,0,\"tab-panel\"],[12],[1,\"\\n        \"],[8,[39,0],null,[[\"@model\"],[[30,0,[\"model\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,\"section\"],[14,1,\"calibrations-tab\"],[14,0,\"tab-panel\"],[12],[1,\"\\n        \"],[8,[39,1],null,[[\"@station\",\"@calibrations\"],[[30,0,[\"model\"]],[30,0,[\"model\",\"calibrations\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,\"section\"],[14,1,\"annotations-tab\"],[14,0,\"tab-panel\"],[12],[1,\"\\n        \"],[8,[39,2],null,[[\"@station\",\"@annotations\"],[[30,0,[\"model\"]],[30,0,[\"model\",\"annotations\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"site-editor\",\"calibration-editor\",\"annotations-editor\"]]",
    "moduleName": "cosmoz-ui/templates/sites/edit.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});