define("cosmoz-ui/metrics-adapters/internal-metrics-adapter", ["exports", "ember-metrics/metrics-adapters/base", "@ember/service", "fetch"], function (_exports, _base, _service, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    moment: (0, _service.inject)(),
    toStringExtension: function toStringExtension() {
      return 'internal-metrics-adapter';
    },
    postToEndpoint: function postToEndpoint(params, done) {
      var config = this.config;
      var metricsEndpoint = config.restEndpoint + "/rest/metrics";
      var esc = encodeURIComponent;
      var query = Object.keys(params).map(function (k) {
        return "".concat(esc(k), "=").concat(esc(params[k]));
      }).join('&');
      var url = metricsEndpoint + "?" + query;
      return (0, _fetch.default)(url, {
        method: 'POST',
        referrerPolicy: 'client' //force client to send referrer

      }).then(function (response) {
        return done(response.json());
      });
    },
    init: function init(self) {
      return self;
    },
    identify: function identify() {
      console.debug("internal-metrics-adapter: identify()");
      console.debug(arguments);
    },
    trackEvent: function trackEvent() {
      console.debug("internal-metrics-adapter: trackEvent()");
      console.debug(arguments);
    },
    trackPage: function trackPage(ev) {
      var m = this.moment;
      var visitTime = m.utc().format();
      this.postToEndpoint({
        'action': 'page_visit',
        'page': ev.page,
        'time': visitTime
      }, function () {//console.debug(arguments);
      });
    },
    alias: function alias() {
      console.debug("internal-metrics-adapter: alias()");
      console.debug(arguments);
    },
    willDestroy: function willDestroy() {// do nothing
    }
  });

  _exports.default = _default;
});