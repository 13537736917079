define("cosmoz-ui/models/daily", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    time: (0, _model.attr)('isodate'),
    min_count: (0, _model.attr)(),
    max_count: (0, _model.attr)(),
    mean_count: (0, _model.attr)(),
    count_count: (0, _model.attr)(),
    min_corr_count: (0, _model.attr)(),
    max_corr_count: (0, _model.attr)(),
    mean_corr_count: (0, _model.attr)(),
    count_corr_count: (0, _model.attr)(),
    min_soil_moist: (0, _model.attr)(),
    max_soil_moist: (0, _model.attr)(),
    mean_soil_moist: (0, _model.attr)(),
    count_soil_moist: (0, _model.attr)(),
    min_effective_depth: (0, _model.attr)(),
    max_effective_depth: (0, _model.attr)(),
    mean_effective_depth: (0, _model.attr)(),
    count_effective_depth: (0, _model.attr)(),
    min_soil_moist_filtered: (0, _model.attr)(),
    max_soil_moist_filtered: (0, _model.attr)(),
    mean_soil_moist_filtered: (0, _model.attr)(),
    count_soil_moist_filtered: (0, _model.attr)(),
    min_depth_filtered: (0, _model.attr)(),
    max_depth_filtered: (0, _model.attr)(),
    mean_depth_filtered: (0, _model.attr)(),
    count_depth_filtered: (0, _model.attr)()
  });

  _exports.default = _default;
});