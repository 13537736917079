define("cosmoz-ui/mixins/remember-scroll", ["exports", "@ember/runloop", "@ember/object/mixin"], function (_exports, _runloop, _mixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Note, put this on all routes, but not on the Application route.
  // When put on the application route the activate() triggers only once
  var _default = _mixin.default.create({
    getScrollXY: function getScrollXY() {
      var scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
          scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      return [scrollLeft, scrollTop];
    },
    setScrollXY: function setScrollXY(parts) {
      document.documentElement.scrollLeft = document.body.scrollLeft = parts[0];
      document.documentElement.scrollTop = document.body.scrollTop = parts[1];
    },
    activate: function activate() {
      var _this = this;

      this._super.apply(this, arguments);

      var self = this;

      if (this.lastScroll) {
        (0, _runloop.next)(function () {
          _this.setScrollXY(self.get('lastScroll'));
        });
      } else {
        this.setScrollXY([0, 0]);
      }
    },
    actions: {
      willTransition: function willTransition() {
        this._super.apply(this, arguments);

        var xy = this.getScrollXY();
        this.set('lastScroll', xy);
      }
    }
  });

  _exports.default = _default;
});